import { useRef, useEffect, useState } from 'react';
import RubiksCube, { materials } from '@curtishughes/rubiks-cube';
import {default as DefaultButton} from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import './App.css';

const Button = withStyles({
  root: {
    textTransform: 'none',
    minWidth: '48px',
    fontSize: '18px',
    fontWeight: 600,
  }
})(DefaultButton);

function App() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [cube, setCube] = useState<RubiksCube>();

  useEffect(() => {
    if (canvasRef.current) {
      setCube(new RubiksCube(canvasRef.current, materials.classic, 100));
    }
  }, []);

  return (
    <main>
      <canvas width="500px" height="500px" ref={canvasRef} />
      <div>
        <div>
          <Button onClick={() => { if (cube) cube.F() }}>F</Button>
          <Button onClick={() => { if (cube) cube.B() }}>B</Button>
          <Button onClick={() => { if (cube) cube.U() }}>U</Button>
          <Button onClick={() => { if (cube) cube.D() }}>D</Button>
          <Button onClick={() => { if (cube) cube.L() }}>L</Button>
          <Button onClick={() => { if (cube) cube.R() }}>R</Button>
        </div>
        <div>
          <Button onClick={() => { if (cube) cube.F(false) }}>F'</Button>
          <Button onClick={() => { if (cube) cube.B(false) }}>B'</Button>
          <Button onClick={() => { if (cube) cube.U(false) }}>U'</Button>
          <Button onClick={() => { if (cube) cube.D(false) }}>D'</Button>
          <Button onClick={() => { if (cube) cube.L(false) }}>L'</Button>
          <Button onClick={() => { if (cube) cube.R(false) }}>R'</Button>
        </div>
      </div>
      <div>
        <div>
          <Button onClick={() => { if (cube) cube.f() }}>f</Button>
          <Button onClick={() => { if (cube) cube.b() }}>b</Button>
          <Button onClick={() => { if (cube) cube.u() }}>u</Button>
          <Button onClick={() => { if (cube) cube.d() }}>d</Button>
          <Button onClick={() => { if (cube) cube.l() }}>l</Button>
          <Button onClick={() => { if (cube) cube.r() }}>r</Button>
        </div>
        <div>
          <Button onClick={() => { if (cube) cube.f(false) }}>f'</Button>
          <Button onClick={() => { if (cube) cube.b(false) }}>b'</Button>
          <Button onClick={() => { if (cube) cube.u(false) }}>u'</Button>
          <Button onClick={() => { if (cube) cube.d(false) }}>d'</Button>
          <Button onClick={() => { if (cube) cube.l(false) }}>l'</Button>
          <Button onClick={() => { if (cube) cube.r(false) }}>r'</Button>
        </div>
      </div>
      <div>
        <div>
          <Button onClick={() => { if (cube) cube.x() }}>x</Button>
          <Button onClick={() => { if (cube) cube.y() }}>y</Button>
          <Button onClick={() => { if (cube) cube.z() }}>z</Button>
        </div>
        <div>
          <Button onClick={() => { if (cube) cube.x(false) }}>x'</Button>
          <Button onClick={() => { if (cube) cube.y(false) }}>y'</Button>
          <Button onClick={() => { if (cube) cube.z(false) }}>z'</Button>
        </div>
      </div>
    </main>
  );
}

export default App;